import React from "react";

function FileIcon() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path d="M2825 5109 c-274 -40 -502 -160 -696 -368 -177 -188 -279 -412 -308 -675 -15 -129 -15 -2230 -1 -2345 25 -193 132 -383 283 -497 131 -100 293 -154 457 -154 120 0 215 22 325 75 199 97 333 258 397 480 l23 80 0 1095 0 1095 -22 40 c-41 77 -105 115 -193 115 -56 0 -100 -18 -143 -59 -66 -61 -61 24 -67 -1171 l-5 -1085 -24 -53 c-49 -109 -170 -186 -291 -186 -121 0 -242 77 -291 186 l-24 53 0 1170 0 1170 23 75 c74 233 228 403 444 489 107 43 181 54 310 49 202 -9 342 -72 488 -218 96 -95 148 -177 188 -295 l27 -80 3 -1305 c3 -1424 4 -1373 -55 -1555 -108 -333 -388 -624 -711 -736 -178 -63 -394 -85 -571 -59 -235 34 -461 144 -635 310 -171 162 -275 345 -348 610 -9 34 -13 351 -17 1290 l-6 1245 -22 40 c-41 77 -105 115 -193 115 -88 0 -176 -64 -200 -147 -8 -27 -10 -391 -7 -1273 3 -1223 3 -1236 25 -1334 102 -467 362 -834 762 -1074 146 -87 320 -153 510 -193 152 -32 438 -33 596 -1 433 86 795 328 1037 690 109 164 193 367 240 580 20 95 21 118 25 1397 2 950 0 1326 -8 1395 -64 520 -471 937 -990 1016 -104 15 -241 17 -335 3z" />
      </g>
    </svg>
  );
}

export default FileIcon;
