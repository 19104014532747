import { createContext, useContext } from "react";

export const darkThemeContext = createContext({
  isDarkTheme: false,
  toggleTheme: () => {},
});

export const DarkThemeProvider = darkThemeContext.Provider;

export default function useTheme() {
  return useContext(darkThemeContext);
}
