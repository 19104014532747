import { useState } from "react";
import "./EmailModal.css";
import CrossIcon from "../Svg/CrossIcon";
import { CONSTANTS } from "../../Constants/Constants";
import useDiffLang from "../../contexts/langContext";

const EmailModal = ({ threadInfos, closeEmailBox }) => {
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const { defaultLang } = useDiffLang();

  const saveChat = () => {
    const { baseUrl } = window.lastbotSettings;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email: emailInput,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    fetch(
      `${baseUrl}/message_threads/${
        threadInfos[threadInfos.length - 1].id
      }/save`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("save SUCCESS.", result);
      })
      .catch((error) => console.log("save Failure error", error));
  };

  const emailSubmitHandler = () => {
    let emailregex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailInput) {
      setEmailError(CONSTANTS.EMAIL_MODAL.reqEmail);
      return;
    }
    if (!emailregex.test(emailInput)) {
      setEmailError(CONSTANTS.EMAIL_MODAL.validEmail);
      return;
    }
    setEmailError("");
    setEmailInput("");
    closeEmailBox();
    saveChat();
  };

  return (
    <div className="lcb-popup">
      <div className="lcb-popup-inner">
        <span className="lcb-popup-cross-icon" onClick={closeEmailBox}>
          <CrossIcon />
        </span>
        <div className="lcb-subscribe">
          <p>{defaultLang.save_title}</p>
          <input
            onChange={(e) => {
              setEmailInput(e.target.value);
            }}
            value={emailInput}
            placeholder={defaultLang.save_placeholder}
            className="lcb-subscribe-input"
            name="email"
            type="email"
          />
          {emailError && (
            <span className="lcb-email-input-error">{emailError}</span>
          )}
          <div onClick={emailSubmitHandler} className="lcb-submit-btn">
            {defaultLang.save_submit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
