import { createContext, useContext } from "react";

export const langContext = createContext({
  defaultLang: {},
  changeLang: () => {},
});

export const LangChangeProvider = langContext.Provider;

export default function useDiffLang() {
  return useContext(langContext);
}
