
import React from "react";

function HangUp() {
  return (
    <svg fill="#FF0000" version="1.1" id="Capa_1" width="800px" height="800px" viewBox="0 0 421.311 421.311">
    <g>
        <g>
            <path d="M280.972,96.739c-69.04-4.715-158.301,2.369-159.037,2.443c-1.998,0.071-49.312,2.036-86.679,38.361    c-3.61,3.506-7.071,7.307-10.263,11.266C-10.775,193.12-0.381,233.542,9.006,253.697c4.819,10.38,18.722,15.564,30.366,11.283    l68.146-25.105c3.255-1.214,6.518-3.326,9.392-6.124c5.878-5.723,9.656-13.634,9.869-20.657l0.868-29.122    c0.127-4.313,2.679-9.615,6.342-13.177c1.574-1.523,3.225-2.59,4.766-3.08c15.056-4.773,40.812-10.098,78.026-8.478    c33.042,1.455,52.277,5.769,62.586,9.132c5.769,1.872,10.136,10.603,10.049,16.867l-0.381,26.299    c-0.162,10.943,7.262,23.566,16.89,28.746l61.307,33.058c9.328,5.022,24.308,5.032,33.28-3.696    c12.025-12.518,25.156-33.932,19.403-64.958C411.019,156.718,355.359,101.805,280.972,96.739z M391.793,261.614    c-4.306,4.148-13.705,4.107-18.936,1.279l-61.307-33.053c-5.773-3.098-10.76-11.582-10.663-18.139l0.376-26.301    c0.152-10.75-7.13-24.687-18.23-28.305c-11.004-3.595-31.347-8.193-65.729-9.702c-38.938-1.699-66.153,3.966-82.111,9.013    c-3.28,1.028-6.55,3.083-9.465,5.911c-5.9,5.733-9.709,13.903-9.93,21.305l-0.879,29.114c-0.165,5.789-6.02,13.979-11.517,16.011    l-68.131,25.101c-5.197,1.909-13.106,0.076-15.531-5.139c-8.224-17.656-17.271-53.148,14.457-92.452    c2.892-3.588,6.027-7.023,9.315-10.214c33.692-32.753,77.183-34.942,79.095-35.028c0.86-0.071,89.766-7.079,157.552-2.458    c66.562,4.542,119.982,54.494,128.103,98.292C413.106,232.903,401.991,251.01,391.793,261.614z"/>
            <path d="M224.753,181.689H197.19c-7.622,0-13.825,6.2-13.825,13.824v60.53c0,0.522-0.198,0.995-0.554,1.36    c-0.371,0.355-0.845,0.544-1.374,0.538l-7.917-0.065c-4.139-0.046-6.604,1.519-7.962,2.844c-3.207,3.198-3.225,8.597-0.023,12.877    l35.589,47.576c2.376,3.174,5.83,5.007,9.48,5.017c3.648,0,7.109-1.817,9.486-4.986l35.581-47.403    c1.661-2.203,2.499-4.493,2.499-6.794c0-4.458-3.57-8.993-10.395-8.993h-7.222c-1.092,0-1.976-0.889-1.976-1.976v-60.532    C238.581,187.889,232.375,181.689,224.753,181.689z M242.181,269.871c0.899,0-0.487,2.828-3.112,6.316l-23.715,31.585    c-2.625,3.494-6.857,3.484-9.473-0.005l-23.701-31.682c-2.613-3.493-3.862-6.316-2.796-6.312l1.935,0.016    c3.707,0.04,7.19-1.377,9.813-3.967c2.623-2.6,4.075-6.083,4.075-9.779v-60.53c0-1.089,0.886-1.975,1.976-1.975h27.565    c1.087,0,1.976,0.886,1.976,1.975v60.53c0,7.621,6.2,13.827,13.822,13.827C240.556,269.871,241.283,269.871,242.181,269.871z"/>
        </g>
    </g>
    </svg>
  );
}

export default HangUp;


