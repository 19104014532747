import React, { useState } from 'react';
import SendIcon from "./Svg/SendIcon";
import useTheme from "../contexts/darkThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const EmailRequestDialog = ({ emailPrompt, onSubmit, onClose, placeholderText }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { isDarkTheme } = useTheme();

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    setError('');
    onSubmit(email);
  };

  return (
    <div className={`lcb_email-request-dialog ${isDarkTheme ? 'lcb_dark-theme' : ''}`}>
      <h2 className="lcb_email-prompt">{emailPrompt}</h2>
      <form onSubmit={handleSubmit} className="lcb_email-form">
        <div className="lcb_input-wrapper">
          <FontAwesomeIcon icon={faExclamationTriangle} className="lcb_alert-icon" style={{ color: 'orange' }} />
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            required
            className="lcb_email-input"
            placeholder={placeholderText}
          />
          <button
            type="submit"
            className={`lcb_sendButton lcb_sendEmailButton ${email ? "highlight" : ""}`}
          >
            <SendIcon />
          </button>
        </div>
        {error && <p className="lcb_error-message">{error}</p>}
        <button type="button" onClick={onClose} className="lcb_cancel-button">
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EmailRequestDialog;
