import React from "react";
import "./ErrorDiv.css";
import ErrorIcon from "../Svg/ErrorIcon";
import ErrorCross from "../Svg/ErrorCross";
import { CONSTANTS } from "../../Constants/Constants";

export default function ErrorDiv({ setShowError, resetChat }) {
  return (
    <div className="lcb-error">
      <div className="lcb-error__icon">
        <ErrorIcon />
      </div>
      <div className="lcb-error__title">{CONSTANTS.ERROR_MESSAGE}</div>
      <div
        onClick={() => {
          setShowError(false);
          resetChat();
        }}
        className="lcb-error__close"
      >
        <ErrorCross />
      </div>
    </div>
  );
}
