import React from "react";

function ImageNotFound() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="600.000000pt"
      height="600.000000pt"
      viewBox="0 0 600.000000 600.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M1257 4653 c-4 -3 -7 -609 -7 -1345 l0 -1338 1750 0 1750 0 -2 1343 -3 1342 -1741 3 c-957 1 -1744 -1 -1747 -5z m3353 -1343 l0 -1190 -1610 0 -1610 0 0 1190 0 1190 1610 0 1610 0 0 -1190z" />
        <path d="M2269 4002 c-39 -20 -62 -41 -84 -77 -27 -43 -30 -57 -30 -125 0 -67 4 -82 28 -118 44 -67 97 -96 181 -100 64 -3 76 0 123 27 131 77 150 257 38 359 -73 68 -167 81 -256 34z" />
        <path d="M3513 3687 c-16 -23 -205 -301 -418 -617 -214 -316 -396 -585 -405 -597 -16 -21 -22 -14 -364 408 -191 237 -351 429 -356 427 -7 -2 -109 -109 -452 -473 l-38 -40 3 -300 2 -300 1515 0 1515 0 3 77 3 78 -487 687 c-268 379 -488 689 -489 690 -1 2 -15 -16 -32 -40z" />
        <path d="M1310 1570 c0 -133 1 -140 20 -140 18 0 20 7 20 85 0 47 3 85 8 85 4 0 29 -39 57 -85 43 -74 53 -85 77 -85 l28 0 0 140 0 140 -25 0 c-24 0 -24 -2 -27 -92 l-3 -93 -55 93 c-43 73 -60 92 -77 92 -23 0 -23 -1 -23 -140z" />
        <path d="M1920 1686 c0 -21 5 -26 26 -26 21 0 25 4 22 23 -2 14 -11 23 -25 25 -19 3 -23 -1 -23 -22z" />
        <path d="M3769 1708 c0 -2 -2 -13 -3 -25 -2 -18 2 -23 21 -23 18 0 23 5 23 25 0 18 -5 25 -20 25 -11 0 -20 -1 -21 -2z" />
        <path d="M3870 1570 l0 -140 25 0 25 0 0 140 0 140 -25 0 -25 0 0 -140z" />
        <path d="M4190 1571 c0 -132 1 -141 19 -141 11 0 21 5 23 11 2 7 14 5 33 -6 86 -48 154 95 82 173 -23 24 -75 30 -95 10 -7 -7 -12 -8 -13 -2 0 5 -1 27 -2 49 -2 33 -6 41 -24 43 -23 3 -23 3 -23 -137z m135 -19 c6 -30 -6 -76 -24 -87 -37 -22 -74 36 -59 92 14 52 71 49 83 -5z" />
        <path d="M4427 1703 c-4 -3 -7 -66 -7 -140 l0 -133 25 0 25 0 0 140 c0 129 -1 140 -18 140 -10 0 -22 -3 -25 -7z" />
        <path d="M1599 1601 c-23 -24 -29 -38 -29 -73 0 -54 19 -83 65 -98 85 -28 160 56 125 141 -28 66 -109 81 -161 30z m103 -28 c10 -9 18 -29 18 -45 0 -28 -30 -68 -52 -68 -22 0 -48 33 -48 62 0 62 41 88 82 51z" />
        <path d="M1920 1530 l0 -100 25 0 25 0 0 100 0 100 -25 0 -25 0 0 -100z" />
        <path d="M2020 1530 l0 -100 25 0 c24 0 25 2 25 69 0 60 3 72 22 85 36 25 48 4 48 -80 0 -72 1 -74 25 -74 24 0 25 2 25 69 0 60 3 72 22 85 36 25 48 4 48 -80 0 -72 1 -74 25 -74 25 0 25 1 25 78 0 90 -15 122 -58 122 -15 0 -37 -7 -49 -15 -18 -13 -22 -13 -33 0 -14 17 -53 20 -81 5 -13 -7 -19 -7 -19 0 0 5 -11 10 -25 10 l-25 0 0 -100z" />
        <path d="M2375 1610 c-14 -16 -16 -23 -7 -32 10 -10 17 -10 32 0 31 19 70 15 70 -7 0 -15 -10 -21 -41 -26 -72 -12 -100 -64 -59 -105 24 -24 45 -25 83 -6 15 8 27 11 27 5 0 -5 10 -9 23 -9 22 0 22 2 17 90 -6 103 -10 110 -81 110 -34 0 -51 -5 -64 -20z m95 -115 c0 -18 -7 -26 -24 -31 -43 -11 -65 24 -28 45 31 19 52 13 52 -14z" />
        <path d="M2591 1604 c-15 -19 -21 -41 -21 -74 0 -62 28 -100 74 -100 17 0 37 5 44 12 17 17 26 4 18 -26 -8 -33 -47 -44 -78 -22 -24 17 -58 21 -58 7 0 -5 8 -19 19 -32 15 -18 28 -23 70 -23 82 0 90 14 92 159 1 107 0 120 -16 123 -10 2 -21 -2 -26 -9 -6 -11 -11 -11 -21 -1 -22 22 -75 15 -97 -14z m89 -14 c10 -5 21 -24 25 -40 12 -55 -39 -102 -73 -68 -19 19 -15 95 6 107 21 13 20 13 42 1z" />
        <path d="M2825 1605 c-23 -22 -30 -49 -26 -100 6 -75 95 -108 154 -57 22 20 22 29 -4 37 -14 5 -25 1 -33 -10 -16 -22 -44 -16 -64 13 l-14 22 65 0 c36 0 68 4 71 8 10 16 -15 81 -37 97 -33 23 -84 19 -112 -10z m95 -30 c17 -21 3 -35 -36 -35 -26 0 -34 4 -34 18 0 10 3 22 7 25 12 12 50 7 63 -8z" />
        <path d="M3138 1608 l-22 -22 22 -9 c15 -7 25 -5 37 7 20 19 26 20 50 3 25 -19 13 -34 -34 -42 -74 -12 -99 -58 -56 -101 21 -21 32 -25 53 -19 15 4 43 7 63 6 l37 0 -3 86 c-3 100 -12 113 -82 113 -31 0 -49 -6 -65 -22z m102 -106 c0 -24 -19 -42 -46 -42 -29 0 -40 31 -18 47 27 20 64 17 64 -5z" />
        <path d="M3320 1623 c0 -4 17 -50 37 -101 33 -84 39 -93 61 -90 21 3 30 17 62 101 l38 98 -26 -3 c-23 -3 -30 -12 -47 -60 -11 -31 -23 -59 -27 -63 -3 -3 -16 23 -28 59 -19 57 -25 66 -46 66 -13 0 -24 -3 -24 -7z" />
        <path d="M3565 1610 c-14 -16 -16 -23 -7 -32 9 -9 18 -9 40 3 32 19 64 14 60 -9 -2 -9 -21 -21 -47 -29 -24 -7 -49 -18 -57 -24 -22 -18 -17 -66 8 -83 28 -20 45 -20 81 -2 15 8 27 11 27 5 0 -5 10 -9 23 -9 21 0 22 3 19 82 -4 102 -16 118 -82 118 -34 0 -52 -5 -65 -20z m95 -108 c0 -23 -19 -42 -42 -42 -27 0 -35 30 -13 46 27 20 55 18 55 -4z" />
        <path d="M3767 1623 c-3 -5 -5 -50 -6 -100 l-2 -93 25 0 26 0 0 100 c0 92 -1 100 -19 100 -11 0 -22 -3 -24 -7z" />
        <path d="M3988 1608 l-22 -22 23 -10 c15 -6 25 -6 29 0 12 18 40 23 58 10 24 -18 11 -33 -37 -41 -43 -8 -79 -35 -79 -60 0 -9 11 -27 26 -41 20 -21 30 -24 52 -18 15 4 43 7 63 6 l35 -2 -4 87 c-5 101 -13 113 -79 113 -31 0 -49 -6 -65 -22z m102 -99 c0 -25 -22 -49 -46 -49 -32 0 -43 28 -19 46 22 17 65 18 65 3z" />
        <path d="M4543 1608 c-47 -51 -36 -145 19 -174 39 -20 92 -11 113 19 14 20 14 22 -6 29 -15 6 -25 3 -33 -8 -16 -21 -45 -15 -64 14 l-14 22 66 0 66 0 0 35 c0 75 -98 116 -147 63z m97 -38 c12 -23 4 -30 -36 -30 -42 0 -50 14 -25 39 22 22 46 19 61 -9z" />
      </g>
    </svg>
  );
}

export default ImageNotFound;
