import React from "react";

function DocumentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M19.5,0h-15C3.673,0,3,0.673,3,1.5v21C3,23.327,3.673,24,4.5,24h15c0.827,0,1.5-0.673,1.5-1.5v-21C21,0.673,20.327,0,19.5,0z M19,22H5V2h14V22z"/>
      <path d="M7 4H17V6H7zM7 8H17V10H7zM7 12H13V14H7z"/>
    </svg>
  );
}

export default DocumentIcon;
