import React, { useEffect, useRef, useState } from "react";
import { ChatHeader } from "./ChatHeader";
import { ChatMessages } from "./ChatMessages";
import { InputMessage } from "./InputMessage";
import { generateRandomString } from "../utils/helper";
import ErrorDiv from "./ErrorDiv/ErrorDiv";
import { Tooltip } from "react-tooltip";
import useTheme from "../contexts/darkThemeContext";
import useDiffLang from "../contexts/langContext";
import { LOCALE_STRINGS } from "../Constants/Constants";
import '@livekit/components-styles';
import EmailRequestDialog from './EmailRequestDialog';

import {
  LiveKitRoom,
  RoomAudioRenderer,
  useLocalParticipant,
  MediaDeviceMenu,
  ConnectionState,
  AudioConference,
  PreJoin
} from '@livekit/components-react';


// Check message is in JSON formate or not (For Error)
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str);
}


export const ChatContainer = () => {
  const scrollRef = useRef();
  const [uuid, setUuid] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [threadInfos, setThreadInfos] = useState([]);
  const [typedMsgsIds, setTypedMsgsIds] = useState([]);
  const [currentUserId, setCurrentUserId] = useState("");

  const [BASE_URL, setBaseUrl] = useState("");

  const [isFetching, setIsFetching] = useState(false); // Flag to control polling

  const [showChat, setShowChat] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [message, setMessage] = useState([]);

  const [inputMessage, setInputMessage] = useState("");
  const [widgetInfo, setWidgetInfo] = useState(null);
  const [showInitialMessages, setShowInitialMessages] = useState([]);

  const [showError, setShowError] = useState(false);
  const [showConsentMsg, setshowConsentMsg] = useState(true);
  const [threadInterval, setThreadInterval] = useState(null);

  const [locale, setLocale] = useState("");
  const [requireEmail, setRequireEmail] = useState(false);

  const htmlElRef = useRef(null);
  const { isDarkTheme } = useTheme();
  const { changeLang } = useDiffLang();
  const { defaultLang } = useDiffLang();
  const fileInputRef = useRef(null);
  const [inlineContainer, setInlineContainer] = useState("");
  const [token, setToken] = useState(null);
  const [url, setUrl] = useState(null);
  const [widgetMode, setWidgetMode] = useState("avatar");


  const connectToLiveKitAudio = async () => {
    const storedUuid = localStorage.getItem("uuid");
    const language_code = locale.language_code || 'en'; // Default to 'en' if not set
    const serverUrl = new URL(`${BASE_URL}/voice_auth`);
    serverUrl.searchParams.append('uuid', storedUuid);
    serverUrl.searchParams.append('language', language_code);

    const response = await fetch(serverUrl.toString());
    const { token, url } = await response.json();
    setToken(token);
    setUrl(url);
    console.log('Token:', token);
    console.log('url:', url);

    // Reset the message_threads fetching interval when audio session starts
    if (threadInterval) clearInterval(threadInterval);
    const newInterval = setInterval(() => {
      getMessageThreads(sessionId, uuid, true);
    }, 5000); // Fetch every 5 seconds for audio sessions
    setThreadInterval(newInterval);
  };


  useEffect(() => {
    const chatContainerElement = document.getElementById('chatContainer'); // Replace with your actual container ID

    const handleLinkClick = (event) => {
      const target = event.target;
      console.log('Link clicked:', target);
      if (target.matches('a[data-open-in-iframe="true"]')) {
        event.preventDefault();
        const url = target.getAttribute('href');
        openLinkInIframe(url);
      }
      else {
        // Open any clicked link in a new tab
        if (target.tagName === 'A') {
          event.preventDefault();
          window.open(target.href, '_blank').focus();
        }
      }
    };

    chatContainerElement.addEventListener('click', handleLinkClick);

    return () => {
      chatContainerElement.removeEventListener('click', handleLinkClick);
    };
  }, []);

  useEffect(() => {
    const settingsTimeout = setTimeout(() => {
      const { autoOpen, baseUrl, inlineContainer, widgetMode } = window.lastbotSettings;
      console.log('window.lastbotSettings:', window.lastbotSettings);
      let updatedBaseUrl = baseUrl;
      if (!baseUrl.startsWith('http')) {
        updatedBaseUrl = `${window.location.protocol}//${window.location.host}${baseUrl}`;
      }
      setBaseUrl(updatedBaseUrl);
      setInlineContainer(inlineContainer);
      setWidgetMode(widgetMode);

      // Automatically open chatBot after 5 seconds if autoOpen is true
      if (autoOpen) {
        const autoOpenTimeout = setTimeout(() => {
          setShowChat(true);
        }, 5000);
        return () => clearTimeout(autoOpenTimeout);
      }
    }, []);

    return () => clearTimeout(settingsTimeout);
  }, []);

  useEffect(() => {
    if (!!BASE_URL && threadInfos.length === 0) {
      const createdSessionId = createSessionId();
      let localStorageUuid = localStorage.getItem("uuid");
      getWidget(createdSessionId, localStorageUuid);
    }
  }, [BASE_URL]);

  useEffect(() => {
    if (inlineContainer && sessionId && (document.getElementById(inlineContainer) || document.querySelector(`.${inlineContainer}`))) {
      let localStorageUuid = localStorage.getItem("uuid");

      getMessageThreads(sessionId, localStorageUuid);
    }
  }, [inlineContainer, sessionId]);

  useEffect(() => {
    if (inlineContainer && message && threadInfos && (document.getElementById(inlineContainer) || document.querySelector(`.${inlineContainer}`))) {
      const container = (document.getElementById(inlineContainer) || document.querySelector(`.${inlineContainer}`));

      const chatContainer = document.createElement('div');
      chatContainer.className = `lcb_inline-container ${isDarkTheme ? 'lcb_dark-theme' : ''}`;

      // Check if widgetInfo is available and use its data
      if (widgetInfo && threadInfos) {
        const widgetHeader = document.createElement('div');
        widgetHeader.className = `widget-info-header lcb_chat-header-wrapper ${isDarkTheme ? 'lcb_dark-theme' : ''}`;
        widgetHeader.innerText = `${widgetInfo.name || 'Widget Name'}`; // Use widget name and add threadInfos first title

        const userAvatarDiv = document.createElement('div');
        userAvatarDiv.className = 'lcb_user-avatar';
        userAvatarDiv.innerText = widgetInfo?.initials || '';

        widgetHeader.prepend(userAvatarDiv);
        chatContainer.appendChild(widgetHeader);
      }

      if (!container.hasChildNodes() && message?.length > 0) {
        const firstMessage = message[0];

        // Create a div for the header row
        const headerRow = document.createElement('div');
        headerRow.className = `content-div ${isDarkTheme ? 'lcb_dark-theme' : ''}`;
        const firstMessageContent = document.createElement('div');
        firstMessageContent.innerHTML = firstMessage.contents;
        headerRow.appendChild(firstMessageContent);
        chatContainer.appendChild(headerRow);

        // Create a div for buttons
        const buttonsRow = document.createElement('div');
        buttonsRow.className = `buttons-div ${isDarkTheme ? 'lcb_dark-theme' : ''}`;
        const options = firstMessage.metadata.options.length > 0 ? firstMessage.metadata.options : firstMessage.metadata.data;
        options.forEach(optionText => {
          const button = document.createElement('button');
          button.className = `${isDarkTheme ? 'lcb_dark-theme' : ''}`;
          button.innerText = optionText;
          button.onclick = () => handleAddMessage(optionText);
          buttonsRow.appendChild(button);
        });
        chatContainer.appendChild(buttonsRow);

        container.appendChild(chatContainer);
      }
    }
  }, [inlineContainer, message, widgetInfo, threadInfos, isDarkTheme]);

  useEffect(() => {
    if (showChat && htmlElRef.current) {
      htmlElRef.current.focus();
    }
  }, [showChat]);

  useEffect(() => {
    const interval = setInterval(() => {
      getMessages(sessionId, uuid, currentUserId);
    }, 1000); // Fetch every second

    // Clear the interval and allow fetching when component unmounts
    return () => clearInterval(interval);
  }, [isFetching]);

  useEffect(() => {
    if (showChat) {
      const { userId } = window.lastbotSettings;

      const createdUuid = createUserId(userId);

      const createdSessionId = createSessionId();
      createNewMsgThread(createdSessionId, createdUuid);
    }

    if (!showChat) {
      if (threadInterval) clearInterval(threadInterval);
    }
  }, [showChat]);

  useEffect(() => {
    if (!isFetching) {
      htmlElRef.current && htmlElRef.current.focus();
    }
  }, [isFetching]);

  const createNewMsgThread = (SessionId, Uuid) => {
    if (threadInfos.length === 0) {
      getMessageThreads(SessionId, Uuid);
    }

    const interval2 = setInterval(() => {
      getMessageThreads(SessionId, Uuid, true);
    }, 30000); // Fetch every 30 seconds

    setThreadInterval(interval2);

    // Clear the interval and allow fetching when component unmounts
    return () => {
      if (threadInterval) clearInterval(threadInterval);
    };
  };

  const createUserId = (userId) => {
    let localStorageUuid = localStorage.getItem("uuid");

    if (localStorageUuid) {
      setUuid(localStorageUuid);
      return localStorageUuid;
    }
    // If userId is set by client
    else if (userId) {
      setUuid(userId);
      localStorage.setItem("uuid", userId);
      return userId;
    } else {
      const newUuid = generateRandomString();
      localStorage.setItem("uuid", newUuid);
      setUuid(newUuid);
      return newUuid;
    }
  };

  const createSessionId = () => {
    let localStorageSessionId = sessionStorage.getItem("sessionId");

    if (localStorageSessionId) {
      setSessionId(localStorageSessionId);
      return localStorageSessionId;
    } else {
      const newSessionId = generateRandomString();
      sessionStorage.setItem("sessionId", newSessionId);
      setSessionId(newSessionId);
      return newSessionId;
    }
  };

  const resetChat = () => {
    localStorage.setItem("lastbot-widget-msg-ids", []);
    setMessage([]);
    setThreadInfos([]);
    setShowInitialMessages([]);
    const { userId } = window.lastbotSettings;
    const createdUuid = createUserId(userId);
    sessionStorage.clear();
    const createdSessionId = createSessionId();
    setTypedMsgsIds([]);
    setIsFetching(false); // Set isFetching to false on reset
    if (threadInterval) clearInterval(threadInterval);
    getMessageThreads(createdSessionId, createdUuid);
    createNewMsgThread(createdSessionId, createdUuid);
  };


  const openLinkInIframe = (url) => {

    if (!inlineContainer) {
      console.log('Inline container not defined');

      const windowName = 'lastbotWindow';
      let existingWindow = window.open('about:blank', windowName);

      try {
        if (existingWindow && !existingWindow.closed) {
          console.log('Opening URL in existing window:', url);
          existingWindow.location.href = url;
          window.focus();
        } else {
          console.log('Opening URL in new window:', url);
          existingWindow = window.open(url, windowName);
          if (existingWindow) {
            existingWindow.focus();
          }
        }
      } catch (error) {
        console.error('Error accessing window properties:', error);
        // If we can't access the existing window, open a new one
        console.log('Opening URL in new window:', url);
        existingWindow = window.open(url, windowName);
        if (existingWindow) {
          existingWindow.focus();
        }
      }
    } else {
      console.log('Opening URL in iframe:', url);
      const container = document.getElementById(inlineContainer) || document.querySelector(`.${inlineContainer}`);
      if (container) {
        container.style.height = '100vh'; // Set the container to the height of the screen
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '100%'; // Ensure the iframe also takes 100% height
        iframe.src = url;
        iframe.onload = function() {
          console.log('Iframe loaded successfully');
          // Clear any existing timeout to prevent the button from being rendered
          clearTimeout(loadCheckTimeout);
        };
        // The "load" event does not catch "X-Frame-Options" errors, so we use a timeout to check if the iframe is loaded
        const loadCheckTimeout = setTimeout(() => {
          if (!iframe.contentWindow || !iframe.contentWindow.location.href) {
            console.error(`Iframe failed to load for URL: ${url}`);
            // If the iframe fails to load, show a button to open the URL in a new tab
            const button = document.createElement('button');
            button.innerText = 'Open in new tab';
            button.onclick = function() {
              window.open(url, '_blank').focus();
            };
            container.innerHTML = ''; // Clear the container before appending the button
            container.appendChild(button);
          }
        }, 2000); // Timeout for 2 seconds

        try {
          container.innerHTML = ''; // Clear the container before appending the iframe
          container.appendChild(iframe);
        } catch (e) {
          clearTimeout(loadCheckTimeout); // Clear the timeout if an error occurs while appending the iframe
          console.error(`Error appending iframe to container: ${e.message}`);
        }
      }
    }
  };
  const setTargetBlank = (originalString) => {
    if (inlineContainer) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(originalString, 'text/html');
      const links = doc.querySelectorAll('a');

      links.forEach(link => {
        link.setAttribute('data-open-in-iframe', 'true'); // Set a data attribute
      });

      return doc.body.innerHTML;
    } else {
      return originalString; // If inlineContainer is not defined, return the original string
    }
  };


  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  const removeTooltip = () => {
    if (!!document.getElementById("lcb_my-tooltip"))
      document.getElementById("lcb_my-tooltip").style.display = "none";
  };

  const setRenderMessages = (result, firstTime) => {
    result.map((item) => {
      item.contents = setTargetBlank(item.contents);
      return item;
    });

    result.forEach((item) => {
      if (item?.metadata?.type === "error") {
        setIsFetching(false); // Stop fetching if new messages are received
        setShowError(true);
      }
    });

    const lastMessage = result[result.length - 1];
    const optionsData = lastMessage.metadata?.type === "options" ? lastMessage.metadata?.data : lastMessage.metadata?.options;

    if (result.length > 0 && optionsData?.length > 0) {
      setShowInitialMessages(optionsData);
    }

    if (firstTime) {
      setMessage(result);
      if (!result[result.length - 1].is_finished) {
        setIsFetching(true);
      }
} else if (
      result[result.length - 1].role === "assistant" &&
      result[result.length - 1].is_finished
    ) {
      setIsFetching(false); // Stop fetching if new messages are received
      setMessage(result); // Update the messages state with new messages
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    } else {
      setMessage(result); // Update the messages state with new messages
      // Check if the user is at the bottom of the chat
      const isUserAtBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop <= scrollRef.current.clientHeight + 100;
      if (isUserAtBottom) {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }
    }
  };

  const getWidget = (sessionId = null, uuid = null) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      BASE_URL +
        `?url=${encodeURIComponent(window.location.href)}${
          sessionId ? `&session_id=${sessionId}` : ""
        }${uuid ? `&uuid=${uuid}` : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWidgetInfo(result);
        setRequireEmail(result.require_email || false);

        if (!!result.locale_strings) {
          changeLang(result.locale_strings);
        } else {
          changeLang(LOCALE_STRINGS);
        }


         // Set the locale state variable
         if (!!result.locale) {
          setLocale(result.locale);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getMessageThreads = (sessionId, uuid, polling = false) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    // Return the fetch call directly
    return fetch(
      `${BASE_URL}/message_threads?session_id=${sessionId}&uuid=${uuid}&url=${
        encodeURIComponent(window.location.href)
      }${polling ? "&ping=true" : ""}`,
      requestOptions
    )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((text) => {
     // console.log('getMessageThreads response:', text);
      try {
        const result = JSON.parse(text);
        setThreadInfos(result);
        setCurrentUserId(result[result.length - 1].id);
        setRenderMessages(result[result.length - 1].messages, true);
        if (result[result.length - 1].messages.length > 1) {
          setRequireEmail(false);
        }

        const url = result[result.length - 1].document

        console.log('URL:', url);

        if(url) {
          openLinkInIframe(url);
        }

        return result;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    })
    .catch((error) => console.log("error", error));
  };

  const getMessages = (sessionId, uuid, currentUserId) => {
    if (!isFetching) {
      return; // Stop fetching if a new message is already received
    }
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/message_threads/${currentUserId}/messages?session_id=${sessionId}&uuid=${uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRenderMessages(result, false);
      })
      .catch((error) => console.log("error", error));
  };

  const postMessage = (inputFromUser, selectedFile) => {
    let myHeaders = new Headers();
    let formData = new FormData();

    formData.append("message[contents]", inputFromUser);

    if (selectedFile) {
      formData.append("message[attachment]", selectedFile);
    }

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/message_threads/${currentUserId}/messages?session_id=${sessionId}&uuid=${uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setMessage((prev) => [...prev, result]);
        setShowInitialMessages([]);
        setIsFetching(true);
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      })
      .catch((error) => console.log("error", error));
  };

  const postRating = (messageId, isGood) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      rating: isGood ? 5 : 0,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${BASE_URL}/message_threads/${
        threadInfos[threadInfos.length - 1].id
      }/messages/${messageId}/rate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("RATE SUCCESS.", result);
      })
      .catch((error) => console.log("RATE Failure error", error));
  };

  const handleAddMessage = (message, selectedFile) => {
    postMessage(message, selectedFile);
    if (showConsentMsg) {
      setshowConsentMsg(false);
      localStorage.setItem("lcb-consent-msg-shown", true);
    }
    if (!showChat) {
      setShowChat(true);
    }
  };

  const submitEmail = (email) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      email: email,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${BASE_URL}/message_threads/${currentUserId}/authenticate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Email submission successful:", result);
        setRequireEmail(false);
      })
      .catch((error) => console.log("Email submission error:", error));
  };

  // disable scroll outside chat window
  const disableScroll = (e) => {
    document.body.style.overflow = "hidden";
  };

  // enable scroll outside chat window
  const enableScroll = (e) => {
    document.body.style.overflow = "auto";
    if (window.innerWidth < 450) {
      setShowChat(false);
    }
  };

  return (
    <div
      id="chatContainer"
      className="lbt_bot"
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      lang={locale.language_code}
    >
      <div
        className={
          showChat
            ? isFullScreen
              ? `lcb_chat-container-desktopOpenView-fullscreen ${isDarkTheme ? "lcb_dark-theme" : ""
              }`
              : window.innerWidth > 769
                ? `lcb_chat-container-desktopOpenView ${isDarkTheme ? "lcb_dark-theme" : ""
                }`
                : `lcb_chat-container ${isDarkTheme ? "lcb_dark-theme" : ""}`
            : widgetMode == "avatar"
              ? `lcb_widget-icon`
              : `lcb_chat-container-desktop ${isDarkTheme ? "lcb_dark-theme" : ""
              }`
        }
      >
        <Tooltip id="lcb_my-tooltip" style={{zIndex: '999'}}/>

        <ChatHeader
          threadInfos={threadInfos}
          setShowChat={setShowChat}
          showChat={showChat}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          widgetInfo={widgetInfo}
          resetChat={resetChat}
          enableScroll={enableScroll}
          disableScroll={disableScroll}
          removeTooltip={removeTooltip}
          messageCount={Math.max(1, message.length)}
          />
        {showError && (
          <ErrorDiv setShowError={setShowError} resetChat={resetChat} />
        )}
        {showChat && (
          <>

            <ChatMessages
              scrollRef={scrollRef}
              isFullScreen={isFullScreen}
              message={message}
              setMessage={setMessage}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              widgetInfo={widgetInfo}
              setFocus={setFocus}
              isFetching={isFetching}
              threadInfos={threadInfos}
              showInitialMessages={showInitialMessages}
              typedMsgsIds={typedMsgsIds}
              setTypedMsgsIds={setTypedMsgsIds}
              postRating={postRating}
              removeTooltip={removeTooltip}
              fileInputRef={fileInputRef}
              handleAddMessage={handleAddMessage}
            />
             {requireEmail ? (
              <EmailRequestDialog
                onSubmit={submitEmail}
                placeholderText={defaultLang.email_prompt}
                onClose={() => setRequireEmail(false)}
              />
            ) : (
              <InputMessage
                showError={showError}
                isFetching={isFetching}
                htmlElRef={htmlElRef}
                isFullScreen={isFullScreen}
                handleAddMessage={handleAddMessage}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                showConsentMsg={showConsentMsg}
                fileInputRef={fileInputRef}
                connectToLiveKitAudio={connectToLiveKitAudio}
                token={token}
                url={url}
                widgetInfo={widgetInfo}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
