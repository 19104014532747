import { useEffect, useState } from "react";
import "./App.css";
import './icons.css';
import "./DarkMode.css";
import { ChatContainer } from "./component/ChatContainer";
import { DarkThemeProvider } from "./contexts/darkThemeContext";
import { LangChangeProvider } from "./contexts/langContext";

function App({ baseUrl, autoOpen, imgSrc, userId, darkMode, inlineContainer, avatarUrl, widgetMode }) {
  const [isDarkTheme, setIsDarkTheme] = useState(darkMode);
  const [defaultLang, setDefaultLang] = useState({});

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const changeLang = (lang_obj) => {
    setDefaultLang(lang_obj);
  };

  useEffect(() => {
    // Check system dark mode
    // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   setIsDarkTheme(true);
    // }

    window.lastbotSettings = {
      // settings we want to configure
      baseUrl,
      autoOpen,
      imgSrc,
      userId,
      inlineContainer,
      avatarUrl,
      widgetMode
    };

    //console.log("window.lastbotSettings", window.lastbotSettings);

  }, []);

  return (
    <LangChangeProvider value={{ defaultLang, changeLang }}>
      <DarkThemeProvider value={{ isDarkTheme, toggleTheme }}>
        <ChatContainer />
      </DarkThemeProvider>
    </LangChangeProvider>
  );
}
export default App;
