import { useState } from "react";
import "./ProductDetailPage.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import { ChatPageForProduct } from "./ChatPageForProduct";
// import BackIcon from "./Svg/BackIcon";
// import UploadIcon from "./Svg/UploadIcon";
// import ChatIcon from "./Svg/ChatIcon";
// import CrossIcon from "../Svg/CrossIcon";
import { Interweave } from "interweave";
import ErrorCross from "../Svg/ErrorCross";
import { Tooltip } from "react-tooltip";
import { CONSTANTS } from "../../Constants/Constants";
import useDiffLang from "../../contexts/langContext";

export const ProductDetailPage = ({
  setShowImagePage,
  singleImageData,
  isFullScreen,
}) => {
  const [showFullCaption, setShowFullCaption] = useState(true);

  const { defaultLang } = useDiffLang();

  // const [showChat, setShowChat] = useState(false);

  let captionTextLength = 70;
  let imageData = singleImageData.images.map((s) => s.url);

  const openProductUrl = (url) => {
    window.open(url, "_blank");
  };

  const formattedNumber = (number, currency) => {
    return Number(number).toLocaleString("en-US", {
      style: "currency",
      currency: currency,
    });
  };

  return (
    <div
      className={`lcb_imageOpenerWrapper ${isFullScreen ? "fullScrreen" : ""}`}
    >
      <Tooltip id="close" />
      <div className="lcb_chat-header-wrapper-prod-detail">
        <span className="lcb_headertext">{singleImageData.name}</span>
        <span
          className="lcb_back-arrow"
          onClick={() => {
            setShowImagePage("");
          }}
          data-tooltip-id="close"
          data-tooltip-content= {defaultLang.close_window}
        >
          {/* <BackIcon /> */}
          <ErrorCross />
        </span>

        {/* <div> */}
        {/* Hide Upload icon as it doing nothing */}
        {/* <span style={{ marginLeft: 8 }}>
            <UploadIcon />
          </span> */}
        {/* Hide Chat Icon for now */}
        {/* <span
            onClick={() => {
              setShowChat(!showChat);
            }}
            style={{ marginLeft: 8 }}
          >
            <ChatIcon />
          </span> */}
        {/* </div> */}
      </div>

      <div
        className={`lcb_image-page-container ${
          isFullScreen ? "fullScrreen" : ""
        }`}
      >
        <Carousel
          showIndicators={false}
          showArrows={true}
          showStatus={true}
          infiniteLoop={false}
          showThumbs={false}
          useKeyboardArrows={true}
          autoPlay={false}
          stopOnHover={false}
          swipeable={true}
          dynamicHeight={true}
          emulateTouch={true}
          autoFocus={true}
        >
          {imageData.map((img) => (
            <div>
              <img src={img} alt="chatImage" />
            </div>
          ))}
        </Carousel>

        <div
          className={`lcb_product-detail ${
            isFullScreen ? "with-full-screen" : ""
          }`}
        >

          {singleImageData?.description && (
            <p className="lcb_single-img-text">
              {showFullCaption ? (
                <Interweave content={singleImageData.description} />
              ) : (
                <Interweave
                  content={singleImageData.description.slice(
                    0,
                    captionTextLength
                  )}
                />
              )}
              <span
                className="lcb_readMoreInImagePage"
                onClick={() => setShowFullCaption((prev) => !prev)}
              >
                {showFullCaption ? defaultLang.view_less : defaultLang.view_more}
              </span>
            </p>
          )}

          {singleImageData?.price && Number(singleImageData.price) > 0 && (
            <span className="lcb_img-title" style={{ margin: "8px 0" }}>
              {formattedNumber(singleImageData.price, singleImageData.currency)}
            </span>
          )}
          <button
            className={`lcb_consultation ${isFullScreen ? "in-full-view" : ""}`}
            onClick={() => openProductUrl(singleImageData.url)}
          >
            { defaultLang.view_details }
          </button>
        </div>
      </div>
      {/* {showChat && <ChatPageForProduct setShowChat={setShowChat} />} */}
    </div>
  );
};
