import React, { useEffect, useState } from "react";

type VisualizerState = "listening" | "idle" | "speaking" | "thinking";
type AgentMultibandAudioVisualizerProps = {
  state: VisualizerState;
  barWidth: number;
  accentColor: string;
  accentShade?: number;
  frequencies: Float32Array[];
  borderRadius: number;
  gap: number;
};

export const AgentMultibandAudioVisualizer: React.FC<AgentMultibandAudioVisualizerProps> = ({
  state,
  barWidth,
  accentColor,
  accentShade,
  frequencies,
  borderRadius,
  gap,
}) => {
  const summedFrequencies = frequencies.map((bandFrequencies) => {
    const sum = bandFrequencies.reduce((a, b) => a + b, 0);
    return Math.sqrt(sum / bandFrequencies.length);
  });

  const [thinkingIndex, setThinkingIndex] = useState(
    Math.floor(summedFrequencies.length / 2)
  );
  const [thinkingDirection, setThinkingDirection] = useState<"left" | "right">(
    "right"
  );

  useEffect(() => {
    if (state !== "thinking") {
      setThinkingIndex(Math.floor(summedFrequencies.length / 2));
      return;
    }
    const timeout = setTimeout(() => {
      if (thinkingDirection === "right") {
        if (thinkingIndex === summedFrequencies.length - 1) {
          setThinkingDirection("left");
          setThinkingIndex((prev) => prev - 1);
        } else {
          setThinkingIndex((prev) => prev + 1);
        }
      } else {
        if (thinkingIndex === 0) {
          setThinkingDirection("right");
          setThinkingIndex((prev) => prev + 1);
        } else {
          setThinkingIndex((prev) => prev - 1);
        }
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [state, summedFrequencies.length, thinkingDirection, thinkingIndex]);

  return (
    <div
      className="lcb_audio-visualizer"
      style={{
        gap: gap + "px",
      }}
    >
      {summedFrequencies.map((frequency, index) => {
        const isCenter = index === Math.floor(summedFrequencies.length / 2);
        const isActive = (state === "listening" && isCenter) ||
                         (state === "speaking") ||
                         (state === "thinking" && index === thinkingIndex);

        const className = `lcb_audio-bar ${isCenter ? 'center' : ''} ${isActive ? 'active' : ''} ${state}`;

        const styles: React.CSSProperties = {
          height: 20 + frequency * (60 - 20) + "px",
          borderRadius: borderRadius + "px",
          width: barWidth + "px",
        };

        return (
          <div
            key={"frequency-" + index}
            className={className}
            style={styles}
          ></div>
        );
      })}
    </div>
  );
};
