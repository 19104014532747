function generateRandomString() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const minLength = 12;
  const maxLength = 16;
  const randomLength =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

  let randomString = "";
  for (let i = 0; i < randomLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function getInitials(name) {
  // Split the name into an array of words
  const words = name.split(" ");

  // Get the first letter of each word
  const initials = words.map((word) => word.charAt(0));

  // Join the initials into a single string
  return initials.join("");
}

export { generateRandomString, getInitials };
